<template>
  <main class="main">
    <!-- <div class="main__video-wrapper">
      <video class="main__video-file" loop muted autoplay playsinline>
        <source src='../video/promo_new.mp4' type="video/mp4">
      </video>
    </div> -->

    <div class="container">
      <div class="main__inner">
        <div class="main__content">
          <h1 class="main__title">
            РАСПРОДАЖА HYUNDAI В РОЛЬФ
          </h1>
          <h2 class="main__subtitle">
            Рекордно низкие цены!
            <br><b>Успейте до подорожания</b><br>
          </h2>
        </div>
        <button
        @click="getCall({type: 'sale', form:'main'})"
        class="button main__button button-dark-blue">
          Узнать цену по акции
        </button>
      </div>
    </div>

    <!-- <div class="main__cars">
      <div class="main__cars-item">
        <p class="main__cars-title">HYUNDAI SOLARIS</p>
        <button
        @click="getCall({type: 'sale', form:'main'})"
        class="main__cars-button button button-dark-blue">
          Забронировать с выгодой
        </button>
      </div>
      <div class="main__cars-item">
        <p class="main__cars-title">НОВАЯ HYUNDAI CRETA</p>
        <button
        @click="getCall({type: 'sale', form:'main'})"
        class="button button-dark-blue">
          Забронировать с выгодой
        </button>
      </div>
    </div> -->
    
  </main>
</template>

<script>
import Mixins from "../common/mixin"
export default {
  mixins:[Mixins],
  data() {
    return {
      date: this.CONSTANTS.end_date
    }
  },
  computed: {
    getNexDate() {
      return `${this.date.getDate()}.${this.date.getMonth() + 1}`;
    }
  }
}
</script>

<style lang="sass" scoped>
.main
    margin-top: 79px
    background: url('../images/bacground/main.jpg') center no-repeat
    height: calc( 100vw - 58vw )
    background-size: cover
    padding-top: 30px
    padding-bottom: 30px
    position: relative
    .container
        height: 100%
    &__video-wrapper
        overflow: hidden
        position: absolute
        left: 0
        top: 0
        z-index: -1
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        height: 100%
        background: rgba(0,0,0, .5) center/cover
    &__content
        padding: 1em
        background-color: hsla(0, 0%, 0%, 0.7)
    &__title
        font-size: 45px
        line-height: 1.2
        font-weight: 700
        font-family: 'Hyundai Sans Head'
        text-shadow: 2px 2px 4px #000
    &__subtitle
        font-size: 35px
        font-weight: 400
        line-height: 1.2
        font-family: 'Hyundai Sans Head'
        text-shadow: 2px 2px 4px #000
    &__inner
        display: flex
        flex-direction: column
        align-items: center
        height: 100%
        text-align: center
        max-width: 1068px
        margin: 0 auto
    &__content
        color: #fff
    &__button
        margin-top: 30px
    &__cars
        position: absolute
        bottom: 0
        width: 100%
        display: flex
        justify-content: center
        align-items: stretch
        &-button
            margin-top: 5px
        &-title, &-subtitle
            font-family: 'Hyundai Sans Text'
            font-size: 20px
            line-height: 29px
        &-item
            padding: 8px 40px 20px
            background-color: rgba(255, 255, 255, 0.65)
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            margin: 0 30px
@media screen and (max-width: 1200px)
    .main
      &__title
        font-size: 30px
      &__subtitle
        font-size: 20px
@media screen and (max-width: 1174px)
    .main__cars-item
      margin: 0 20px
    .main
        height: calc( 100vw * 650 / 1920)
        padding-top: 16px
        margin-top: 0
@media screen and (max-width: 1000px)
    .main
      &__title
        font-size: 22px
      &__subtitle
        font-size: 25px
@media screen and (max-width: 859px)
    .main
        background-size: cover
        //height: 750px

        &__title
          font-size: 20px
        &__subtitle
          font-size: 20px
    .main__cars
        right: 0
        width: auto
        left: 0

@media screen and (max-width: 700px)
  .main
    &__title
      font-size: 17px
    &__subtitle
      font-size: 18px

@media screen and (max-width: 594px)
    .container
      height: 100%
    .main__inner
      max-width: calc(100vw - 6vw)
      height: 100%
      display: flex
      flex-direction: column
    .main
        background: url('../images/bacground/main_m.jpg') bottom center no-repeat
        background-size: cover
        height: calc(100vw / 550 * 550)
    .main__title
        font-size: 16px
        line-height: 1.2
    .main__subtitle
        font-size: 16px
        line-height: 1.2
    .main__button
        margin-top: auto
    .main__cars
        flex-direction: column
        margin: 0px
        padding-top: 20px
    .main__cars-button
        display: none
    .main__cars-item
        padding: 5px 5px
        margin: 0px
    .main__cars
      padding-top: 10px
      background: rgba(255,255,255,0.6)
    .main__cars-item
      background: transparent
    .main__content
@media screen and (max-width: 432px)
    .header__nav
        height: 40px

@media screen and (max-width: 350px)
    .main__cars
      padding-top: 10px

</style>
